import React from 'react';
import { Redirect } from 'react-router';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
//import CustomizedSlider from './Slider';
import Floor from './FloorSVG';
import Mainhall from './floorplans/MainSVG';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Exhcarousel from './ExhCarousel';
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const halls = [
    { label: 'Overall', value: '/' },
    { label: 'Hall 1 & 2', value: '/hall-1-2' },
    { label: 'Hall 3', value: '/hall-3' },
    { label: 'Hall 4 & 5', value: '/hall-4-5' },
    { label: 'Hall 6', value: '/hall-6' },
    // {label:'Hall 7', value: '/hall-7'},
    { label: 'Hall 8', value: '/hall-8' },
    // {label:'Hall 9', value: '/hall-9'}
];

class Mainfloor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hall: '',
            halllabel: '',
            redirect: false,
            selectedex: null,
        };
    };

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };


    _onSelect = (option) => {
        if (option.label !== this.props.deff) {
            this.setState({
                hall: option.value,
                halllabel: option.label,
                redirect: true,
            });
        }
    }

    handleChange = (event) => {
        this.setState({
            name: event.target.value,
        });

    };

    locateBooth = (e) => {
        var booth = Number(e.target.title)
        this.setState({
            selectedex: booth,
        });
    }

    unlocateBooth = () => {
        this.setState({
            selectedex: '',
        });
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                selectedex: this.props.location.state.exid,
            });
        }
    }

    render() {
        if (this.state.redirect && this.props.deff !== this.state.halllabel) {
            return <Redirect push to={this.state.hall} />;
        }

        //const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let vhe = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vhe}px`);

        if (this.props.deff === 'Overall') {
            return (
                <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    wheel={{ wheelEnabled: false }}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <div className="floormap-9">
                                <div className="drop-sm-2" style={{ maxWidth: 200 }}>
                                    <span className="drop-title">Select Hall</span>
                                    <Dropdown options={halls} onChange={this._onSelect} value={this.props.deff} controlClassName='dropdown-shadow' />
                                </div>
                                <div className="zoom-btn-grp" style={{ maxWidth: 400 }}>
                                    <button className="btn zoom-btn" onClick={zoomOut}>-</button>
                                    <button className="btn zoom-btn" onClick={resetTransform}>Reset</button>
                                    <button className="btn zoom-btn" onClick={zoomIn}>+</button>
                                </div>
                                <TransformComponent>
                                    <div className="zoom-div mainhall-padding">
                                        <Mainhall />
                                    </div>
                                </TransformComponent>
                            </div>
                        </React.Fragment>
                    )}
                </TransformWrapper>)
        } else {
            return (
                <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    wheel={{ wheelEnabled: false }}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <div className="floormap">
                                <div className="drop-sm-2" style={{ maxWidth: 200 }}>
                                    <span className="drop-title">Select Hall</span>
                                    <Dropdown options={halls} onChange={this._onSelect} value={this.props.deff} controlClassName='dropdown-shadow' />
                                </div>
                                <div className="zoom-btn-grp" style={{ maxWidth: 400 }}>
                                    <button className="btn zoom-btn" onClick={zoomOut}>-</button>
                                    <button className="btn zoom-btn" onClick={resetTransform}>Reset</button>
                                    <button className="btn zoom-btn" onClick={zoomIn}>+</button>
                                </div>
                                <TransformComponent>
                                    <div className="zoom-div">
                                        <Floor selected={this.state.selectedex} hall={this.props.deff} />
                                    </div>
                                </TransformComponent>
                                <Exhcarousel locBooth={this.locateBooth} unlocBooth={this.unlocateBooth} selected={this.state.selectedex} hall={this.props.deff} />
                            </div>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            )
        }
    };
};

export default withRouter(Mainfloor);